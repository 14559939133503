import request from "../request";

export function fetchList(data) {
  return request({
    url: "/model-analysis/manageSupervise/queryWIthPage",
    method: "post",
    data,
  });
}

export function fetchDetail(data) {
  return request({
    url: `/model-analysis/manageSupervise/query/${data.id}`,
  });
}

export function edit(data) {
  return request({
    url: `/model-analysis/manageSupervise/update`,
    method: "post",
    data,
  });
}

export function add(data) {
  return request({
    url: `/model-analysis/manageSupervise/save`,
    method: "post",
    data,
  });
}
