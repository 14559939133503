<template>
  <Card title="监督管理">
    <div>
      <a-form-model layout="inline" @keyup.enter.native="query" style="margin-bottom: 2vh">
        <a-form-model-item>
          <a-input v-model="form.content" placeholder="上报内容" />
        </a-form-model-item>
        <a-form-model-item>
          <a-range-picker style="width: 100%" :placeholder="['上报开始时间', '上报结束时间']" :show-time="{
            hideDisabledOptions: true,
            defaultValue: [
              moment('00:00:00', 'HH:mm:ss'),
              moment('23:59:59', 'HH:mm:ss'),
            ],
          }" format="YYYY-MM-DD HH:mm:ss" v-model="form.time" />
        </a-form-model-item>
        <a-form-model-item>
          <a-select v-model="form.status" placeholder="处理状态" style="width: 150px">
            <a-select-option v-for="item in typeList" :key="item.value" :value="item.value">{{ item.name
              }}</a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item>
          <a-space>
            <a-button type="primary" @click="query">查询</a-button>
            <a-button @click="reset">重置</a-button>

            <a-button style="margin-left: 20px;" @click="visible = true" type="primary">扫码上报</a-button>
          </a-space>
        </a-form-model-item>
      </a-form-model>

      <a-modal :visible="visible" dialogClass="modal" title="扫码上报" cancelText="关闭" :footer="null"
        @cancel="visible = false">
        <img src="@/assets/supervise/code.png" style="width:100%" />
      </a-modal>


      <a-table bordered @change="onChange" :data-source="list" :loading="loading" rowKey="id" :pagination="{
        size: 'small',
        total,
        current,
        pageSize,
        showTotal: (total) => `共 ${total} 条记录`,
      }">
        <a-table-column title="序号" width="50px" align="center">
          <template slot-scope="text, row, index">{{ index + 1 }}</template>
        </a-table-column>

        <a-table-column title="上报内容" width="150px" data-index="content" align="center"></a-table-column>

        <a-table-column title="上报人联系方式" width="80px" data-index="reporterContact" align="center" />

        <a-table-column title="上报时间" width="80px" data-index="reporteTime" align="center" />

        <a-table-column title="处理人" width="80px" data-index="processor" align="center" />
        <a-table-column title="处理时间" width="80px" data-index="processTime" align="center" />
        <a-table-column title="处理说明" width="100px" data-index="remark" align="center" />

        <a-table-column title="状态" width="80px" data-index="status" align="center">
          <template slot-scope="text">
            <DataDictFinder dictType="superviseStatus" :dictValue="text" iconType="text" />
          </template>
        </a-table-column>

        <a-table-column title="操作" width="90px" align="center">
          <template slot-scope="text">
            <a @click="$router.push(`${$route.path}/detail?id=${text.id}`)">立即处理</a>
          </template>
        </a-table-column>
      </a-table>
    </div>
  </Card>
</template>
<script>
import { fetchList } from "@/api/supervise";
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  data() {
    return {
      form: {},
      list: [],
      loading: false,
      total: 0,
      current: 1,
      pageSize: 10,

      visible: false,
    };
  },
  mounted() {
    this.getList();
  },
  computed: {
    ...mapGetters("setting", ["findDataDict"]),
    typeList() {
      return this.findDataDict("superviseStatus");
    },
  },
  methods: {
    moment,

    getList() {
      this.loading = true;
      let { current, pageSize, form } = this;
      const { content, status } = form;

      let startTime, endTime;
      if (Array.isArray(form.time) && form.time.length === 2) {
        startTime = form.time[0].format("YYYY-MM-DD HH:mm:ss");
        endTime = form.time[1].format("YYYY-MM-DD HH:mm:ss");
      }

      fetchList({
        pageNum: current,
        pageSize,
        content,
        status,
        startTime,
        endTime,
      })
        .then((res) => {
          this.loading = false;
          if (Array.isArray(res.list)) {
            this.list = res.list;
            this.total = res.totalSize;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    onChange(pagination) {
      this.current = pagination.current;
      this.pageSize = pagination.pageSize;
      this.getList();
    },
    reset() {
      this.form = {};
      this.current = 1;
      this.getList();
    },
    query() {
      this.current = 1;
      this.getList();
    },
  },
};
</script>
<style lang="less">
.modal {
  width: 250px;

  .ant-modal-body {
    text-align: center;
  }
}
</style>
