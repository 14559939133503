var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Card',{attrs:{"title":"监督管理"}},[_c('div',[_c('a-form-model',{staticStyle:{"margin-bottom":"2vh"},attrs:{"layout":"inline"},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.query.apply(null, arguments)}}},[_c('a-form-model-item',[_c('a-input',{attrs:{"placeholder":"上报内容"},model:{value:(_vm.form.content),callback:function ($$v) {_vm.$set(_vm.form, "content", $$v)},expression:"form.content"}})],1),_c('a-form-model-item',[_c('a-range-picker',{staticStyle:{"width":"100%"},attrs:{"placeholder":['上报开始时间', '上报结束时间'],"show-time":{
          hideDisabledOptions: true,
          defaultValue: [
            _vm.moment('00:00:00', 'HH:mm:ss'),
            _vm.moment('23:59:59', 'HH:mm:ss'),
          ],
        },"format":"YYYY-MM-DD HH:mm:ss"},model:{value:(_vm.form.time),callback:function ($$v) {_vm.$set(_vm.form, "time", $$v)},expression:"form.time"}})],1),_c('a-form-model-item',[_c('a-select',{staticStyle:{"width":"150px"},attrs:{"placeholder":"处理状态"},model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}},_vm._l((_vm.typeList),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.name))])}),1)],1),_c('a-form-model-item',[_c('a-space',[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.query}},[_vm._v("查询")]),_c('a-button',{on:{"click":_vm.reset}},[_vm._v("重置")]),_c('a-button',{staticStyle:{"margin-left":"20px"},attrs:{"type":"primary"},on:{"click":function($event){_vm.visible = true}}},[_vm._v("扫码上报")])],1)],1)],1),_c('a-modal',{attrs:{"visible":_vm.visible,"dialogClass":"modal","title":"扫码上报","cancelText":"关闭","footer":null},on:{"cancel":function($event){_vm.visible = false}}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require("@/assets/supervise/code.png")}})]),_c('a-table',{attrs:{"bordered":"","data-source":_vm.list,"loading":_vm.loading,"rowKey":"id","pagination":{
      size: 'small',
      total: _vm.total,
      current: _vm.current,
      pageSize: _vm.pageSize,
      showTotal: (total) => `共 ${total} 条记录`,
    }},on:{"change":_vm.onChange}},[_c('a-table-column',{attrs:{"title":"序号","width":"50px","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(text, row, index){return [_vm._v(_vm._s(index + 1))]}}])}),_c('a-table-column',{attrs:{"title":"上报内容","width":"150px","data-index":"content","align":"center"}}),_c('a-table-column',{attrs:{"title":"上报人联系方式","width":"80px","data-index":"reporterContact","align":"center"}}),_c('a-table-column',{attrs:{"title":"上报时间","width":"80px","data-index":"reporteTime","align":"center"}}),_c('a-table-column',{attrs:{"title":"处理人","width":"80px","data-index":"processor","align":"center"}}),_c('a-table-column',{attrs:{"title":"处理时间","width":"80px","data-index":"processTime","align":"center"}}),_c('a-table-column',{attrs:{"title":"处理说明","width":"100px","data-index":"remark","align":"center"}}),_c('a-table-column',{attrs:{"title":"状态","width":"80px","data-index":"status","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(text){return [_c('DataDictFinder',{attrs:{"dictType":"superviseStatus","dictValue":text,"iconType":"text"}})]}}])}),_c('a-table-column',{attrs:{"title":"操作","width":"90px","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(text){return [_c('a',{on:{"click":function($event){return _vm.$router.push(`${_vm.$route.path}/detail?id=${text.id}`)}}},[_vm._v("立即处理")])]}}])})],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }